<script lang="ts">
import { OpenLanguageKeysConstant } from "@/common/constant/OpenLanguageKeysConstant";
import {$inj, $injByInterface} from "@/common/decorators/depinject";
import { QueryPropertyKey } from "@/open/app/factories/config/queryProperties.enum";
import { QueryPropertiesService } from "@/open/app/factories/config/queryProperties.service";
import { defineComponent } from "vue";
import ColorSchemeUtil from "@/open/services/utils/ColorSchemeUtil";
import FdicBanner from "@/open/components/FdicBanner/FdicBanner.vue";
import UatBanner from "@/common/components/uatBanner/uatBanner.vue";
import IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";

export default defineComponent({
  name: "openToolbar",
  components: {UatBanner, FdicBanner},
  props: {
    messageOverride: {
      type: String,
      default: ""
    }
  },
  data: () => {
    return {
      attributes: {
        toolbarTitle: "",
        isLogoLoaded: true,
        logoB64: "",
        bltTitle: "",
        toolbarTitleKey: OpenLanguageKeysConstant.OPEN_TOOLBAR_TITLE,
      },
      isHeadless: false,
      workspaceMetadataStore: $injByInterface<IWorkspaceMetadataStore>("IWorkspaceMetadataStore")
    };
  },
  setup() {
    const queryPropertiesService = $inj(QueryPropertiesService);
    const colorSchemeUtil = $inj(ColorSchemeUtil);
    return {
      queryPropertiesService,
      colorSchemeUtil
    };
  },
  created() {
    this.isHeadless = this.getHeadlessProperty();
    const institutionConfig = (window as any).institutionConfig;
    this.attributes.toolbarTitle = institutionConfig.name;
    this.attributes.logoB64 = this.colorSchemeUtil.onColorSchemeChange();
  },
  methods: {
    getHeadlessProperty() {
      return !!this.queryPropertiesService.getBool(QueryPropertyKey.HEADLESS);
    }
  }
});
</script>
<template>
  <uat-banner v-if="workspaceMetadataStore.languageConstants?.SHOW_UAT_BANNER"/>
  <fdic-banner></fdic-banner>

  <div id="page-header" :v-if="!isHeadless">
    <div>
      <img v-bind:alt="attributes.toolbarTitle" :src="attributes.logoB64" v-if="attributes.isLogoLoaded" />
    </div>
  </div>
  <div id="content">
    <div id="contentpaddingwrapper">
      <slot></slot>
    </div>
  </div>
</template>
