<template>
  <section
    v-if="isVisible"
    style="display: none">
    <teleport to="body">
      <div
        @click="handleBackdropClick($event)"
        :class="{
          'blt-dialog-backdrop-open': isAnimationVisible,
          'blt-dialog-backdrop-close': !isAnimationVisible
        }"></div>
      <dialog
        ref="dialog"
        :class="{
          open: isAnimationVisible,
          hide: !isAnimationVisible,
          smallDialog: setDialogType('small-dialog'),
          largeDialog: setDialogType('large-dialog'),
          largeDialogCentered: setDialogType('large-dialog-centered'),
          normalDialog: setDialogType('normal-dialog')
        }">
        <jh-card
          class="banno-web-dialog-wrapper"
          :class="stateClass">
          <div
            slot="jh-card-header"
            class="jh-card-header"
            :class="{'hide-border': !dialogBorder}"
            v-if="showHeader">
            <slot name="dialog-header">
              <button
                class="header-back-button inner-screen-modal-back"
                v-if="innerScreenBackBtn"
                @click.stop="innerScreenModalBackBtn">
                <jha-icon-chevron-back></jha-icon-chevron-back>
              </button>
              <h1 v-if="title">{{ title }}</h1>
              <button
                v-if="!hideCloseButton"
                type="button"
                @click="cancelDialog"
                class="reset">
                <jha-icon-close></jha-icon-close>
              </button>
            </slot>
          </div>
          <div
            class="banno-web-dialog-content-wrapper"
            :style="disclosureHeightHotfix ? {maxHeight: 'calc(100dvh - 260px)'} : {}">
            <div class="content">
              <slot name="dialog-content">
                <jha-icon-circle-warning v-if="icon == StatusConstants.WARNING"></jha-icon-circle-warning>
                <jha-icon-circle-checkmark v-else-if="icon == StatusConstants.SUCCESS"></jha-icon-circle-checkmark>
                <h3 v-if="messageHeader">{{ messageHeader }}</h3>
                <p v-if="messageBody">{{ messageBody }}</p>
              </slot>
            </div>
          </div>
          <div
            slot="jh-card-footer"
            class="footer"
            v-if="showFooter">
            <slot name="dialog-footer">
              <jha-button
                outline
                sync
                @click="cancelDialog"
                v-if="showCancel"
                :aria-label="cancelLabel"
                :disabled="cancelDisabled"
                :block="fullWidthButtons"
                >{{ cancelLabel }}</jha-button
              >
              <jha-button
                primary
                sync
                @click="confirmDialog"
                v-if="showConfirm"
                :aria-label="confirmLabel"
                :disabled="confirmDisabled"
                :block="fullWidthButtons"
                :isLoading="isLoading"
                >{{ confirmLabel }}</jha-button
              >
            </slot>
          </div>
        </jh-card>
      </dialog>
    </teleport>
  </section>
</template>

<script lang="ts">
import {StatusConstants} from '@/common/constant/StatusConstants'
import {defineComponent} from 'vue'
import '@banno/jha-wc/src/buttons/jha-button/jha-button.js'
import '@banno/jha-wc/src/icons/jha-icon-close.js'
import '@banno/jha-wc/src/icons/jha-icon-circle-warning.js'
import '@banno/jha-wc/src/icons/jha-icon-circle-checkmark.js'

export default defineComponent({
  name: 'bltModalDialog',
  emits: ['cancel', 'confirm', 'backButton','open','close'],
  props: {
    /**
     * Temporary hotfix for:
     * https://banno-jha.atlassian.net/browse/LDMOD8-1319
     */
    disclosureHeightHotfix: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    messageHeader: {
      type: String,
      default: null
    },
    messageBody: {
      type: String,
      default: null
    },
    cancelLabel: {
      type: String,
      default: 'CANCEL'
    },
    confirmLabel: {
      type: String,
      default: 'OK'
    },
    icon: {
      type: String,
      default: null
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    preventAutoClose: {
      type: Boolean,
      default: false
    },
    showConfirm: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    },
    clickOutsideToClose: {
      type: Boolean,
      default: true
    },
    escapeToClose: {
      type: Boolean,
      default: true
    },
    showDialog: {
      type: Boolean,
      required: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    fullWidthButtons: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'normal-dialog' // "small-dialog" or "large-dialog"
    },
    dialogBorder: {
      type: Boolean,
      default: true
    },
    innerScreenBackBtn: {
      type: Boolean,
      default: false
    },
    stateClass: {
      type: String
    },
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    StatusConstants,
    isVisible: false,
    isAnimationVisible: false
  }),
  mounted() {
    window.document.addEventListener('keydown', this.closeOnEscape)
  },
  unmounted() {
    document.body.classList.remove('has-dialog')
    window.document.removeEventListener('keydown', this.closeOnEscape)
  },
  methods: {
    handleBackdropClick(e: any) {
      if (e.target.className === 'blt-dialog-backdrop-open' && this.clickOutsideToClose) {
        this.cancelDialog()
      }
    },
    setDialogType(type: string) {
      if (this.type === type) {
        return true
      } else return false
    },
    cancelDialog() {
      this.$emit('cancel')
      this.close()
    },
    confirmDialog() {
      this.$emit('confirm')
      if (!this.preventAutoClose) this.close()
    },
    closeOnEscape(e: KeyboardEvent) {
      if (this.escapeToClose && e.key == 'Escape') {
        this.cancelDialog()
      }
    },
    open() {
      document.body.classList.add('has-dialog')
      this.isAnimationVisible = true
      this.isVisible = true
      this.$emit('open')
    },
    close() {
      /**@Cody, this code is now handling undefined as it threw error in employee channel which blocked all the development */
      const ctlDialog = this.$refs.dialog as HTMLDialogElement
      if (ctlDialog) {
        ctlDialog.addEventListener('animationend', () => (this.isVisible = false), {once: true})
      }

      document.body.classList.remove('has-dialog')

      //  todo(mikol): This doesn't make sense, it is set to false while the animation is happening...
      this.isAnimationVisible = false
      this.$emit('close')
    },
    innerScreenModalBackBtn() {
      document.body.classList.add('has-dialog')
      this.isAnimationVisible = true
      this.isVisible = true
      this.$emit('backButton')
    }
  },
  watch: {
    showDialog: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.open()
        } else {
          this.close()
        }
      }
    }
  }
})
</script>

<style scoped>
.popup-wraper {
  margin-left: auto;
  max-width: 600px;
  margin-right: auto;
}

button.reset {
  touch-action: manipulation;
  background: none;
  border: none;
  color: var(--body-text-primary-color);
  cursor: pointer;
  display: block;
  font-family: 'roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
}

.banno-web-dialog-wrapper {
  display: block;
  margin-left: 0px;
  margin-right: 0px;
  background-color: var(--jh-card-color-background, var(--jh-color-container-primary-enabled));
  border-radius: var(--jh-card-border-radius, var(--jh-border-radius-400));
  box-shadow: var(--jh-card-shadow, var(--jh-shadow-100));
  word-break: break-word;
  position: relative;
  align-items: center;
  width: 100%;
}

.banno-web-dialog-content-wrapper {
  overflow: auto;
  padding: var(--jh-space-100);
  max-height: calc(100vh - 260px);
}

h1 {
  color: var(--body-text-primary-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  flex: 1;
}

.jh-card-header {
  display: flex;
  overflow: auto;
  margin-right: 0px;
  padding-right: var(--jh-space-600);
  padding-left: var(--jh-space-600);
  padding-top: var(--jh-space-400);
  padding-bottom: var(--jh-space-300);
  border-bottom: 1px solid var(--jha-border-color);
}

.jh-card-header button {
  flex: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: center;
  padding-top: var(--jh-space-400);
  padding-bottom: var(--jh-space-400);
  border-top: 1px solid var(--jha-border-color);
}
.jh-card-header h1 {
  padding-right: 0px;
  padding-left: var(--jh-space-600);
}
.footer jha-button {
  margin-right: 8px;
  min-width: 120px;
  --jha-button-link-font-weight: 500;
  --jha-button-font-weight: 500;
}

jha-icon-circle-checkmark,
jha-icon-circle-warning {
  width: 120px;
  height: 120px;
}

banno-web-dialog-wrapper ::slotted(*[slot='dialog-content']) {
  width: 100%;
}
/* Uploader dialog */

.servicesState .banno-web-dialog-content-wrapper {
  overflow: auto;
  max-height: fit-content;
  padding: 0px;
}

@media only screen and (min-width: 375px) and (max-width: 1023px) {
  .banno-web-dialog-wrapper {
    width: 99%;
  }
  .banno-web-dialog-content-wrapper {
    overflow: auto;
    /* max-height: calc(100vh - 275px); */
  }
  .blt-id-uploader-dialog {
    max-height: fit-content;
  }
  .jh-card-header {
    display: flex;
    overflow: auto;
  }
  .hide-border {
    border: none !important;
  }
}

/* Start these below two media quires should be applied exclusively to service module  */
/* for both "banno-web-dialog-wrapper" & "banno-web-dialog-content-wrapper" replace banno word with service for the dynamic class  */
@media only screen and (min-width: 1024px) and (max-width: 1180px) {
  .servicesState {
    max-height: calc(105vh - 75px);
    width: 99%;
    overflow: auto;
    margin-top: -28px;
    height: fit-content;
  }
  .servicesState .banno-web-dialog-content-wrapper {
    overflow: auto;
    max-height: fit-content;
  }
}

@media only screen and (min-width: 280px) and (max-width: 1023px) {
  .servicesState {
    max-height: calc(105vh - 75px);
    width: 99%;
    overflow: auto;
    margin: auto;
    height: fit-content;
  }
  .servicesState .banno-web-dialog-content-wrapper {
    overflow: auto;
    max-height: fit-content;
  }
}

.blt-id-uploader-dialog {
  margin-left: 0px;
  margin-right: 0px;
  background-color: var(--jh-card-color-background, var(--jh-color-container-primary-enabled));
  border-radius: var(--jh-card-border-radius, var(--jh-border-radius-400));
  box-shadow: var(--jh-card-shadow, var(--jh-shadow-100));
  word-break: break-word;
  position: relative;
  align-items: center;
  width: 100%;
}

.blt-id-uploader-dialog .banno-web-dialog-content-wrapper {
  max-height: calc(100vh - 250px);
  padding-left: 0px;
  padding-right: 0px;
}
@media only screen and (max-width: 599px) {
  .footer {
    padding-left: var(--jh-space-400);
    padding-right: var(--jh-space-400);
  }

  :deep(.jh-card-header) {
    padding-left: var(--jh-space-400);
    padding-right: var(--jh-space-400);
  }
}
.largeDialogCentered {
  animation-duration: 0.5s;
  display: flex;
  justify-content: center;
  max-width: 800px;
  max-width: 800px;
  top: 0;
  bottom: 0;
}

.largeDialog {
  animation-duration: 0.5s;
  display: flex;
  justify-content: center;
  max-width: 800px;
  max-width: 800px;
  top: 48px;
}

.smallDialog {
  animation-duration: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 600px;
  top: 0px;
}
.blt-dialog-backdrop-open,
.blt-dialog-backdrop-close {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  animation-name: fadeIn;
  animation-duration: 0.5s;
  z-index: 999;
}

.blt-dialog-backdrop-close {
  animation-name: fadeOut;
}
/* end  */
</style>
