<script setup lang="ts">

import {$inj} from "@/common/decorators/depinject";
import ConstantFactory from "@/common/services/utils/ConstantFactory";
import {onMounted, ref, watch} from "vue";

const constantFactory = $inj(ConstantFactory)
const showUatBanner = ref(false)

onMounted(() => {
  showUatBanner.value = constantFactory.getBool("SHOW_UAT_BANNER") ?? false
})

watch(showUatBanner, (newval) => {
  newval
   ? window.document.documentElement.classList.add('showUatBanner')
   : window.document.documentElement.classList.remove('showUatBanner')
})
</script>
<template>
  <div id="UAT_BANNER"
       v-if="showUatBanner">
    UAT
  </div>
</template>

<style scoped>
#UAT_BANNER {
  text-align: center;
  line-height: 24px;
  background-color: var(--jha-text-theme);
  color: var(--primary-button-text-color);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5000;
}
</style>

<style>
html.showUatBanner,
html.showUatBanner body {
  height: calc(100dvh - 24px);
  min-height: calc(100dvh - 24px);
}

html.showUatBanner .left-sidebar,
html.showUatBanner .right-content {
  height: calc(100dvh - 24px);
}

html.showUatBanner .sidebar-container,
html.showUatBanner .sidebar-flex-column-wrapper {
  height: calc(100dvh - 96px);
}
</style>