import {injectable, inject} from "inversify";
import
{Connect, type ConnectEventHandlers, type ConnectOptions}
    from "connect-web-sdk";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class FinicityService {
    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {
    }

    async open(workspaceUUID: string): Promise<string> {
        return await this.httpHandler.post(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/finicity-connect-url`,
            {},
            {},
            ResponseTypes.Payload
        );
    }

    getAccounts(workspaceUUID: string): Promise<Array<SimpleFinicityAccountWithBalance>> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/finicity-accounts`,
            {},
            ResponseTypes.Payload
        );
    }

    saveFunding(workspaceUUID: string, dto: FundTransactionFinicityAccountDTO): Promise<FundTransactionFinicityAccountDTO> {
        return this.httpHandler.post(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/finicity-funding`,
            dto,
            {},
            ResponseTypes.Payload
        )
    }

    getFundings(workspaceUUID: string): Promise<Array<FundTransactionFinicityAccountDTO>> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/finicity-funding`,
            {},
            ResponseTypes.Payload
        )
    }

    launch(connectUrl: string, connectEventHandlers: ConnectEventHandlers, connectOptions: ConnectOptions) {
        return Connect.launch(connectUrl, connectEventHandlers, connectOptions);
    }
}

export interface FundTransactionFinicityAccountDTO {
    fundTransactionId: number,
    finicityAccountId: string,
    amount: number
}

export interface SimpleFinicityAccountWithBalance {
    finicityId: string,
    accountNumberDisplay: string,
    finicityAccountType: string,
    name: string,
    balance: number
}

export default FinicityService;
